<template>
  <div class="directory-menu__container" :class="{ active: $store.state.showDirectoryMenu }" :key="refresh">
    <div @click="closeMenu" class="directory-menu__bg"></div>
    <div class="directory-menu">
      <div class="directory-menu__header">
        Список сотрудников
      </div>
      <div class="directory-menu__body">
        <a @click.prevent="() => { addEmployee = !addEmployee }" class="directory-menu__step directory-add__btn" href="#">
          <PlusIcon/>
          <span>Добавить сотрудника</span>
        </a>
        <form class="directory-menu__body__add" id="add-employee-form" v-if="addEmployee" onsubmit="return false;">
          <div style="margin-left: 40px">
            <label class="directory-menu__body__item">
              <div class="lk__avatar">
                <div class="lk__avatar__image">
                  <img :src="avatar.image || `${BASE_URL}/avatar?user`" alt="Аватар"/>
                  <!-- <img v-else :src="`${BASE_URL}/avatar?user=${this.$store.state.me._id}`" alt="user"> -->
                  <!-- <LkUser v-else/> -->
                </div>
                <div class="avatar">
                  <vue-dropzone
                    ref="avatar"
                    id="avatar"
                    @vdropzone-thumbnail="thumbGenerated"
                    @vdropzone-files-added="filePicked"
                    :includeStyling="false"
                    useCustomSlot
                    :options="dropzoneOptions">
                    <div class="lk__dz">
                      <i>
                        <PaperClip/>
                      </i>
                      <span>{{ avatar.name ? avatar.name : 'Выбрать файл' }}</span>
                    </div>
                  </vue-dropzone>
                </div>
              </div>
            </label>
            <label class="directory-menu__body__item">
              <span>ФИО</span>
              <input required v-model="newEmployee.name" type="text">
            </label>
            <label class="directory-menu__body__item" >
              <span>Дата рождения</span>
              <input required v-model="newEmployee.birthday" type="date">
            </label>
            <label class="directory-menu__body__item" >
              <span>Должность</span>
              <input required v-model="newEmployee.post" type="text">
            </label>
            <label class="directory-menu__body__item" >
              <span>Внутренний номер</span>
              <input v-model="newEmployee.workphone" type="number">
            </label>
            <label class="directory-menu__body__item" >
              <span>Личный номер телефона</span>
              <input v-model="newEmployee.personal_phone" type="text">
            </label>
            <label class="directory-menu__body__item" >
              <span>Раб.номер телефона</span>
              <input v-model="newEmployee.additional_phone" type="text">
            </label>
            <label class="directory-menu__body__item" >
              <span>Email</span>
              <input required v-model="newEmployee.email" type="text">
            </label>
            <label class="directory-menu__body__item" >
              <span>Подразделение</span>
              <v-select
                v-model="newEmployee.subdivision"
                :options="subdivisionsModel"
                placeholder="Подразделение"
              />
            </label>
            <label class="directory-menu__body__item" >
              <span>Адрес подразделения</span>
              <v-select
                label="address"
                v-model="newEmployee.address"
                :options="selectOptions.address"
                placeholder="Адрес подразделения"
              />
            </label>
            <label class="directory-menu__body__item" >
              <span>Отдел</span>
              <v-select
                v-model="newEmployee.department"
                :options="departmentsModel"
                placeholder="Отдел"
              />
            </label>
            <label class="directory-menu__body__item" >
              <span>Описание</span>
              <textarea v-model="newEmployee.description" class="directory-menu__body__item__textarea" cols="30" rows="10" placeholder="Кто такой? Чем известен?"></textarea>
            </label>
          </div>
        </form>
        <ul class="directory-menu__list">
          <li v-for="(item, index) in allDirectories" :id="item.id" :key="index">
            <div class="directory-menu__step">
              <span class="directory-menu__step-title" v-if="item.name">{{ item.name }}</span>
              <div class="directory-menu__step-actions">
                <span @click="item.expanded = !item.expanded; ++refresh; avatar = { image: null, name: null }">
                  <SettingsIcon/>
                </span>
                <span @click="deleteDirectory(item.id)" class="directory__elem-delete">
                  <DeleteIcon/>
                </span>
              </div>
            </div>
            <div class="directory-menu__body__change" v-if="item.expanded">
              <label class="directory-menu__body__item">
                <div class="lk__avatar">
                  <div class="lk__avatar__image">
                    <img :src="avatar.image || `${BASE_URL}/avatar?user=${item.user_id}`" alt="Аватар"/>
                  </div>
                  <div class="avatar">
                    <vue-dropzone
                      ref="avatar"
                      id="avatar"
                      @vdropzone-thumbnail="thumbGenerated"
                      @vdropzone-files-added="updateFilePicked($event, item.user_id)"
                      :includeStyling="false"
                      useCustomSlot
                      :options="dropzoneOptions">
                      <div class="lk__dz">
                        <i>
                          <PaperClip/>
                        </i>
                        <span>{{ avatar.name ? avatar.name : 'Выбрать файл' }}</span>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </label>
              <label class="directory-menu__body__item" >
                <span>ФИО</span>
                <input v-model="item.name" type="text">
              </label>
              <label class="directory-menu__body__item" >
                <span>Должность</span>
                <input v-model="item.post" type="text">
              </label>
              <label class="directory-menu__body__item" >
                <span>Внутренний номер</span>
                <input v-model="item.workphone" type="number">
              </label>
              <label class="directory-menu__body__item" >
                <span>Личный номер телефона</span>
                <input v-model="item.personal_phone" type="text">
              </label>
              <label class="directory-menu__body__item" >
                <span>Доб.номер телефона</span>
                <input v-model="item.additional_phone" type="text">
              </label>
              <label class="directory-menu__body__item" >
                <span>Email</span>
                <input v-model="item.email" type="text">
              </label>
              <label class="directory-menu__body__item" >
                <span>Подразделение</span>
                <v-select
                  v-model="item.subdivision"
                  :options="subdivisionsModel"
                  placeholder="Отдел"
                />
              </label>
              <label class="directory-menu__body__item" >
                <span>Адрес подразделения</span>
                <input v-model="item.address" type="text">
              </label>
              <label class="directory-menu__body__item" >
                <span>Отдел</span>
                <v-select
                  v-model="item.department"
                  :options="departmentsModel"
                  placeholder="Отдел"
                />
              </label>
              <label class="directory-menu__body__item" >
                <span>Описание</span>
                <textarea v-model="item.description" class="directory-menu__body__item__textarea" cols="30" rows="10" placeholder="Кто такой? Чем известен?"></textarea>
              </label>
            </div>
          </li>
        </ul>
      </div>
      <div class="directory-menu__bottom">
        <button @click="closeMenu" class="btn btn--gray">Отмена</button>
        <button type="submit" v-if="addEmployee" @click="saveData" form="add-employee-form" class="btn">Добавить сотрудника</button>
        <button v-else @click="editDirectory" class="btn">Сохранить изменения</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vue2Dropzone from 'vue2-dropzone'
import PaperClip from '@/components/svg/PaperClip'
import PlusIcon from '@/components/svg/plus.vue'
import SettingsIcon from '@/components/svg/settings.vue'
import DeleteIcon from '@/components/svg/DeleteIcon.vue'
import ADD_DIRECTORY from '@/graphql/mutations/AddDirectory.gql'
import DELETE_DIRECTORY from '@/graphql/mutations/DeleteDirectory.gql'
import UPDATE_DIRECTORIES from '@/graphql/mutations/UpdateDirectories.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
// import SelectComponent from '@/components/ui/GraphInputs/SelectInput'

const BASE_URL = process.env.VUE_APP_HTTP

const _graphQlHelper = new GraphQLHelper()

const getTemplate = () => `<div class="dz-preview dz-file-preview"></div>`

export default {
  name: 'DirectoryMenu',
  components: {
    vueDropzone: vue2Dropzone,
    PaperClip,
    PlusIcon,
    SettingsIcon,
    DeleteIcon
    // SelectComponent
  },
  data () {
    return {
      BASE_URL,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 120,
        previewTemplate: getTemplate(),
        maxFiles: 1,
        maxFilesize: 2, // 2MB
        acceptedFiles: 'image/png, image/jpg, image/jpeg'
      },
      avatar: {
        image: null,
        name: null
      },
      avatarChangedList: [],
      refresh: 0,
      file: null,
      selectOptions: {
        subdivision: [
          'Бэк-офис',
          'Финансовая дирекция',
          'Коммерческая дирекция',
          'Филиал г. Нижнекамск',
          'Филиал г. Альметьевск',
          'Филиал г. Набережные Челны',
          'Филиал г. Уфа',
          'Филиал г. Самара',
          'Филиал г. Екатеринбург',
          'Филиал г. Тюмень',
          'Филиал г. Санкт-Петербург',
          'Филиал г. Москва',
          'Филиал г. Нижний Новгород'
        ],
        department: [
          'Юридический отдел',
          'Отдел кадров',
          'IT-отдел',
          'Служба безопасности',
          'Отдел финансирования',
          'Отдел аналитики',
          'Отдел сопровождения',
          'Отдел залогов',
          'Бухгалтерия',
          'Отдел дистанционных продаж',
          'Отдел спецтехники',
          'Отдел автотранспорта',
          'Отдел маркетинга',
          'Управляющая компания'
        ],
        address: [
          'ООО  «Лизинг-Трейд», 420021, Республика Татарстан, г. Казань, ул.Галиаскара Камала, д.41, офис 406',
          'ООО  «Лизинг-Трейд», 420570, г. Нижнекамск, ул. Юности 15',
          'ООО  «Лизинг-Трейд», 23462, г. Альметьевск, ул. Белоглазова, д. 133 Б, оф.14',
          'ООО  «Лизинг-Трейд», 423802 г. Набережные Челны, пр. Мира, д. 16 Б, 2 эт ( 423800,  а/я 00097)',
          'ООО  «Лизинг-Трейд», 443080, г. Самара, Московское ш., д. 41, оф. 401',
          'ООО  «Лизинг-Трейд», 620075, г. Екатеринбург, ул. Малышева д.51 БЦ «Высоцкий» оф. 7/17',
          'ООО  «Лизинг-Трейд», 625000, г. Тюмень, ул.Герцена, д. 96, офис 2, этаж 2',
          'ООО  «Лизинг-Трейд», 450074, г. Уфа, ул. Софьи Перовской, д. 52/2',
          'ООО  «Лизинг-Трейд», 195027 г.Санкт-Петербург, ул. Магнитогорская, д. 23, корпус 1, лит. А, офис 529',
          'ООО  «Лизинг-Трейд», 117105 г. Москва ул. Варшавское шоссе дом 9 стр.1 офис 54',
          'ООО  «Лизинг-Трейд», 603022 г.Н.Новгород, ул.Кулибина, д.3, офис 403',
          'Управляющая компания'
        ]
      },
      newEmployee: {
        name: '',
        birthday: '',
        post: '',
        workphone: '',
        personal_phone: '',
        additional_phone: '',
        email: '',
        address: '',
        department: '',
        subdivision: '',
        status: '',
        description: ''
      },
      addEmployee: false
    }
  },
  watch: {
    '$route.name' () {
      this.loadDirectories()
    }
  },
  computed: {
    departmentsModel () {
      return this.$store.state.allDepartments
    },
    subdivisionsModel () {
      return this.$store.state.allSubdivisions
    },
    'newEmployee.address': {
      get: function () {
        return this.newEmployee.address
      },
      set: function (value) {
        if (value === 'Адрес подразделения') this.newEmployee.address = null
        this.newEmployee.address = value
      }
    },
    allDirectories: {
      get: function () {
        if (this.$store.state.checkedDirectories && this.$store.state.checkedDirectories.length) {
          return this.$store.state.checkedDirectories
        }
        return []
      }
    }
  },
  methods: {
    thumbGenerated (e, dataUrl) {
      this.avatar.name = e.name
      this.avatar.image = dataUrl
    },
    filePicked (e) {
      this.file = e[0]
    },
    updateFilePicked (e, userId) {
      this.file = e[0]
      this.submitDropFile(userId)
      this.avatar = {
        image: null,
        name: null
      }
    },
    submitDropFile (userId) {
      if (!this.file) return

      const url = `${BASE_URL}/avatar`
      let formData = new FormData()
      formData.append('user', userId)
      formData.append('filename', this.file.upload.uuid)
      formData.append('avatar', this.file)
      axios.post(url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(res => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Аватарка успешно добавлена'
        })
        // this.$router.go()
      }
      ).catch((e) => {
        this.$notify({
          group: 'lsg-notify',
          text: 'Ошибка при добавлении аватарки сотруднику'
        })
        throw new Error(e)
      })
    },
    updateDirectoriesBeforeDelete (id) {
      this.allDirectories = this.allDirectories.filter((item) => {
        if (item.id !== item) return item
      })
    },
    async deleteDirectory (id) {
      await this.$apollo.mutate({
        mutation: DELETE_DIRECTORY,
        variables: {
          id
        },
        update: (_, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Сотрудник успешно удалён'
            })
            this.$store.state.allDirectories = this.allDirectories.filter((item) => {
              if (item.id !== data.deleteDirectory.id) return item
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Ошибка при удалении. Обратитесь в службу поддержки'
          })
        })
        throw new Error(error)
      })
    },
    async saveData () {
      if (this.addEmployee) {
        await this.$apollo.mutate({
          mutation: ADD_DIRECTORY,
          variables: {
            input: this.newEmployee
          },
          update: (_, { data }) => {
            if (data) {
              this.submitDropFile(data.addDirectory.user_id)
              this.$notify({
                group: 'lsg-notify',
                text: 'Сотрудник сохранен'
              })
              this.refresh++
              this.closeMenu()
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            if (typeof item === 'string' && item.split(':')[0] === 'Directory validation failed') {
              this.$notify({
                group: 'lsg-notify',
                text: 'Поля заполнены неверно'
              })
            } else {
              this.$notify({
                group: 'lsg-notify',
                text: 'Ошибка при сохранении. Обратитесь в службу поддержки'
              })
            }
          })
          throw new Error(error)
        })
      }
    },
    // изменение
    async editDirectory () {
      const input = this.$store.state.checkedDirectories.map(elem => {
        delete elem.vgt_id
        delete elem.vgtSelected
        delete elem.originalIndex
        delete elem.__typename
        delete elem.expanded
        return elem
      })
      await this.$apollo.mutate({
        mutation: UPDATE_DIRECTORIES,
        variables: {
          input
        },
        update: (_, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Данные о сотрудниках изменены'
            })
            this.$router.go()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Ошибка при сохранение. Обратитесь в службу поддержки'
          })
        })
        throw new Error(error)
      })
    },
    async loadDirectories () {
      await Promise.all([
        this.$store.dispatch('allDirectories', this)
      ])
    },
    closeMenu () {
      this.$store.state.showDirectoryMenu = false
    }
  }
}
</script>

<style lang="stylus" scoped>
  .avatar {
    display flex
    flex-direction column

    .dz-preview {
      display none
    }
  }
</style>

<style lang="stylus">
.directory {
  &-menu__container {
    &.active {
      .directory-menu__bg {
        display block
      }

      .directory-menu {
        transform translateX(0)
      }
    }
  }

  &-menu__bg {
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 999
    background-color rgba($black, 0.5)
    display none
  }

  &-menu {
    position fixed
    top 0
    right 0
    bottom 0
    z-index 1000
    transition transform 0.3s
    transform translateX(100%)
    display flex
    flex-direction column
    width 100%
    max-width 400px
    height 100vh
    background-color $white

    &__header {
      font-weight: 500;
      font-size 1.2em
      line-height: 21px;
      padding 27px 30px 32px
      border-bottom 1px solid $pinky
    }

    &__body {
      display flex
      flex-direction column
      flex 1

      &__add, &__change {
        display flex
        flex-direction column
        align-items center
        overflow-y auto
      }

      &__change {
        background-color $gray
      }

      &__item {
        &:first-child {
          margin-top 10px
        }
        &:not(:first-child) {
          margin-top 10px
        }
        display inline-block
        width 80%
        min-height 60px

        &__textarea {
          resize none
        }

        > span {
          display inline-block
          color gray
          margin-bottom 5px
        }
      }
    }

    &__list {
      flex 1
      margin 0
      padding 0
      overflow-y auto
      scrollbar-width: thin;
    }

    &__step {
      display flex
      align-items center
      padding 15px 30px
      font-size: 1.1em
      line-height: 24px;
      border-bottom 1px solid $pinky
      transition 0.2s

      &:hover {
        background-color $gray
      }

      &.directory-add__btn {
        padding 18px 30px
        text-align center
        justify-content center
        gap 5px
        color $orange

        svg {
          width 20px
          height 20px

          path {
            stroke $orange
          }
        }
      }

      &-title {
        flex-grow 1
      }

      &-actions {
        display flex
        gap 5px
        margin-left auto

        span {
          display flex
          align-items center
          justify-content center
          width 30px
          min-width 30px
          height 30px
          border-radius: 10px;
          padding 7px
          transition 0.3s
          cursor pointer

          svg {
            width 16px
            height 16px

            path[stroke] {
              stroke $darkgray
            }

            path[fill] {
              fill $darkgray
            }
          }

          &.active,
          &:hover {
            color $white
            background-color $orange

            svg {
              path[stroke] {
                stroke $white
              }

              path[fill] {
                fill $white
              }
            }
          }
        }

        .directory__elem-delete {
          &.active,
          &:hover {
            background-color $red
          }
        }
      }
    }

    &__bottom {
      display grid
      grid-template-columns repeat(2, 1fr)
      padding 30px
      gap 16px
      border-top 1px solid $pinky

      button {
        font-size: 15px;
        line-height: 22px;
        text-transform uppercase
      }
    }
  }
}
</style>
